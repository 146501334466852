import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';

@Component({
  selector: 'app-flags-menu',
  templateUrl: './flags-menu.component.html',
  styleUrls: ['./flags-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlagsMenuComponent implements OnInit {

  public settings: Settings;
  constructor(public appSettings: AppSettings, private translate: TranslateService) {
    this.settings = this.appSettings.settings;
    translate.setDefaultLang('en');
    
    if (this.settings.rtl === false)
      this.useLanguage('en', '01-02');
    else
      this.useLanguage('ar', '01-01');
  }

  useLanguage(language: string, languageId: string): void {
    localStorage.setItem("lang", language);
    localStorage.setItem("langId", languageId);

    this.translate.use(language);
    this.changeRtl(language == 'ar');
  }

  public changeRtl(rtl) {
    this.settings.rtl = rtl;
    localStorage.setItem('rtl', this.settings.rtl === false ? "false" : "true");
  }

  ngOnInit() {
  }

}
