import { environment } from './../../../../environments/environment.prod';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resourcesUrl'
})
export class ResourcesUrlPipe implements PipeTransform {

  transform(data): string {
    return environment.systemUrl + "api/Resources?path=" + data;
  }

}
