import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private sessionUserSubscription;
    private sessionUserService;
    private sessionUserRole;
    currentUserSubscription: Guid;

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const sessionUser = this.authenticationService.currentUserValue;
        if (sessionUser && sessionUser.token && sessionUser.roles && sessionUser.roles.length > 0 && sessionUser.services && sessionUser.services.length > 0) {
            // check if route is restricted by role
            this.sessionUserService = localStorage.currentUserService || sessionUser.services[0].id;
            localStorage.setItem('currentUserService', this.sessionUserService);
            localStorage.setItem('currentUserSubscription', sessionUser.services[0].subscriptionId.toString());

            var roles = sessionUser.roles.filter(p => p.serviceId == this.sessionUserService);
            this.sessionUserRole = localStorage.currentUserRole || roles[0].name;

            if (roles.findIndex(p => p.name == this.sessionUserRole) == -1) {
                this.sessionUserRole = roles[0].name;
            }

            localStorage.setItem('currentUserRole', this.sessionUserRole);
            if (state.url == "/") {
                if (this.sessionUserRole == "Teacher" || this.sessionUserRole == "Supervisor") {
                    this.router.navigate(['exams'])
                }
                else if (this.sessionUserRole == "SubTeacher" || this.sessionUserRole == "SubSupervisor") {
                    this.router.navigate(['exams/published-exams'])
                }
                else if (this.sessionUserRole == "SubscriptionAdmin") {
                    this.router.navigate(['setups/services-management'])
                }
                else if (this.sessionUserRole == "Student") {
                    this.router.navigate(['exams/student-exams'])
                }
                else if (this.sessionUserRole == "SurveyCreator") {
                    this.router.navigate(['surveys'])
                }
                else if (this.sessionUserRole == "EvaluationCreator") {
                    this.router.navigate(['evaluations'])
                }
                else if (this.sessionUserRole == "EvaluationAssigner") {
                    this.router.navigate(['evaluations/published-evaluations'])
                }
                else if (this.sessionUserRole == "SurveyAssigner") {
                    this.router.navigate(['surveys/published-surveys'])
                }
            }
            // authorised so return true
            return true;
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}