import { environment } from './../../../../environments/environment';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'fullUrl'
})
export class FullUrlPipe implements PipeTransform {
    transform(data, type): string {
        if (data) {
            return environment.systemUrl + data;
        }
        else {
            switch (type) {
                case "image":
                    return "./assets/img/noImage.png";
                case "audio":
                    return "./assets/img/empty.mp3";
                case "video":
                    return "./assets/img/empty.mp4";
            }
        }
        return null;
    }

}