import { Component, OnInit, Output, HostListener, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  @Output() public settingToggle = new EventEmitter();
  public optionsPsConfig: PerfectScrollbarConfigInterface = {};
  public settings:Settings;
  public menuOption:string;
  private defaultMenu:string; //declared for return default menu when window resized 
  public menus = ['vertical', 'horizontal'];
  public menuTypes = ['default', 'compact', 'mini'];
  public menuTypeOption:string;

  constructor(public appSettings:AppSettings, public router:Router) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.menuOption = this.settings.menu; 
    this.menuTypeOption = this.settings.menuType; 
    this.defaultMenu = this.settings.menu;
  }
  @HostListener('window:resize')
  public onWindowResize():void {
    if(window.innerWidth <= 960){
      this.settings.sidenavIsOpened = false;
      this.settings.sidenavIsPinned = false;
      this.settings.menu = 'vertical'
    }
    else{
      (this.defaultMenu == 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical'
      this.settings.sidenavIsOpened = true;
      this.settings.sidenavIsPinned = true;
    }
  }
  public onToggleSetting = () => {
    this.settingToggle.emit();
  }
  public changeFixedHeader()
  {
    this.settings.fixedHeader = !this.settings.fixedHeader;
    localStorage.setItem('fixedHeader', this.settings.fixedHeader === false? "false" : "true" ); 
  }
  public changeFixedSidenav()
  {
    this.settings.fixedSidenav = !this.settings.fixedSidenav;
    localStorage.setItem('fixedSidenav', this.settings.fixedSidenav === false? "false" : "true" ); 
  } 
  public changeFixedFooter()
  {
    this.settings.fixedFooter = !this.settings.fixedFooter;
    localStorage.setItem('fixedFooter', this.settings.fixedFooter === false? "false" : "true" ); 
  } 
  public changeRtl()
  {
    this.settings.rtl = !this.settings.rtl;
    localStorage.setItem('rtl', this.settings.rtl === false? "false" : "true" ); 
  } 
  public chooseMenu(){
    this.settings.menu = this.menuOption; 
    this.defaultMenu = this.menuOption;
    localStorage.setItem('menu', this.menuOption); 
    if(this.menuOption == 'horizontal'){
      this.settings.fixedSidenav = false;
      localStorage.setItem('fixedSidenav', "false"); 
    }
    this.router.navigate(['/']); 
  }
  public chooseMenuType(){
    this.settings.menuType = this.menuTypeOption;
    localStorage.setItem('menuType', this.menuTypeOption); 
  }
  public changeTheme(theme){
    this.settings.theme = theme;  
    localStorage.setItem('theme', theme);     
  }
  public changeSidenavIsOpened()
  {
    this.settings.sidenavIsOpened = !this.settings.sidenavIsOpened;
    localStorage.setItem('sidenavIsOpened', this.settings.sidenavIsOpened === false? "false" : "true" ); 
  } 
  public changeSidenavIsPinned()
  {
    this.settings.sidenavIsPinned = !this.settings.sidenavIsPinned;
    localStorage.setItem('sidenavIsPinned', this.settings.sidenavIsPinned === false? "false" : "true" ); 
  } 
  public changeFixedSidenavUserContent()
  {
    this.settings.fixedSidenavUserContent = !this.settings.fixedSidenavUserContent;
    localStorage.setItem('fixedSidenavUserContent', this.settings.fixedSidenavUserContent === false? "false" : "true" ); 
  } 
}
