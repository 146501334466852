import { Router, NavigationEnd } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;

  constructor(public appSettings: AppSettings,
    public router: Router) {
    this.settings = this.appSettings.settings;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        setTimeout(() => {
          if (!gtag) return;
          switch (location.hostname.toLowerCase()) {
            case "demo.edulinkin.com":
              gtag('config', "G-S6EHYC162C", { 'page_path': event.urlAfterRedirects });
              break;
            case "exam.edulinkin.com":
              gtag('config', "G-KC1CZ9SF6B", { 'page_path': event.urlAfterRedirects });
              break;
            case "ltmstage.edulinkin.com":
              gtag('config', "G-KZ5M85H9HT", { 'page_path': event.urlAfterRedirects });
              break;
            case "ltm.edulinkin.com":
              gtag('config', "G-QPXDG4XE6X", { 'page_path': event.urlAfterRedirects });
              break;
            case "ltmisastage.edulinkin.com":
              gtag('config', "G-B88H1M7LEP", { 'page_path': event.urlAfterRedirects });
              break;
            case "ltmisa.edulinkin.com":
              gtag('config', "G-T3DHS9MCET", { 'page_path': event.urlAfterRedirects });
              break;
            case "ridwanstage.edulinkin.com":
              gtag('config', "G-V04RRHTGQC", { 'page_path': event.urlAfterRedirects });
              break;
            case "ridwan.edulinkin.com":
              gtag('config', "G-6N7QCM3C5J", { 'page_path': event.urlAfterRedirects });
              break;
            case "ridwanprod.edulinkin.com":
              gtag('config', "G-6N7QCM3C5J", { 'page_path': event.urlAfterRedirects });
              break;
          }
        }, 1000);
      }
    })
  }

  ngOnInit() {
  }

  showOrHideSpinner(show) {
    if (show) {
      document.getElementById('app-spinner').classList.add("hide");
    }
    else {
      document.getElementById('app-spinner').classList.remove("hide");
    }
  }

}