import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from './../../../shared-component/message-dialog/message-dialog.component';
import { Guid } from 'guid-typescript';
import { SetupsService } from './../../../pages/Setups/setups.service';
import { Router } from '@angular/router';
import { SessionUser } from './../../../auth/session.user';
import { AuthenticationService } from './../../../auth/authentication.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService]
})
export class SidenavComponent implements OnInit {
  public psConfig: PerfectScrollbarConfigInterface = {
    wheelPropagation: true
  };
  public menuItems: Array<any>;
  public settings: Settings;
  private currentUser: SessionUser;
  private currentUserService;
  private currentUserRole;
  private currentUserLogo;
  currentUserSubscription: Guid;
  returnUrl: string = null;

  constructor(public appSettings: AppSettings, public menuService: MenuService,
    private authenticationService: AuthenticationService,
    public router: Router,
    public setupsService: SetupsService,
    public messageDialog: MatDialog,
    public translate: TranslateService) {
    this.settings = this.appSettings.settings;

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      this.currentUserService = localStorage.currentUserService;
      this.currentUserRole = localStorage.currentUserRole;
      this.currentUserService = localStorage.currentUserService;
      this.currentUserSubscription = localStorage.currentUserSubscription;
      this.currentUserLogo = localStorage.currentUserLogo || "";
    }
    this.returnUrl = localStorage.returnUrl;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
    this.getSubscriptionLogo();
  }

  ngDoCheck() {
    if (this.settings.fixedSidenav) {
      if (this.psConfig.wheelPropagation == true) {
        this.psConfig.wheelPropagation = false;
      }
    }
    else {
      if (this.psConfig.wheelPropagation == false) {
        this.psConfig.wheelPropagation = true;
      }
    }
  }

  public closeSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

  public changeCurrentUserService() {
    localStorage.setItem('currentUserService', this.currentUserService);
    location.href = "/";
  }

  public getSubscriptionLogo() {
    this.setupsService.getSubscriptionLogo(this.currentUserService).subscribe(result => {
      this.currentUserLogo = result.logo.fileUrl;
      localStorage.setItem('currentUserLogo', this.currentUserLogo);
    });
  }

  public changeCurrentUserRole() {
    localStorage.setItem('currentUserRole', this.currentUserRole);
    location.href = "/";
  }

  private backToUrlSystem() {
    this.authenticationService.logout();
    //window.location.href = this.returnUrl;
  }

  openMessageDialog(message: string): void {
    let dialogRef = this.messageDialog.open(MessageDialogComponent, {
      data: { message: message }
    });
  }


}
