import { catchError } from 'rxjs/operators';
import { ReportQuestionsAnswersCountFilter } from './../Exam/exams/exams.model';
import { Exam } from './../Exam/exam/exam.model';
import { ExamGradeDivisions } from './../Exam/exam-assign/exam-assign.model';
import { Subscription, Curriculum, Grade, Constant } from '../../pages/Setups/setups.model';
import { Guid } from 'guid-typescript';
import { environment } from './../../../environments/environment';
import { MainCurriculum, Service, Subject, AcademicYear, MainSubject, AcademicYearGrade, GradeSubject, Student, PagedGradeStudent, Semester, GradeDivision, Staff, PagedStaff, DivisionsSubjects, UsersRoles } from './setups.model';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Report, ReportFilter } from '../Reports/reports.model';

@Injectable({
  providedIn: 'root'
})
export class SetupsService {

  constructor(public http: HttpClient) { }

  getConstant(): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant`);//.pipe(catchError(this.handelError));
  }

  getConstantGroup(mId: string, langId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetGroup/${mId}?langId=${langId}`);//.pipe(catchError(this.handelError));
  }

  getConstantsMainGroups(mId: string, langId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetConstantsMainGroups/${mId}?langId=${langId}`);//.pipe(catchError(this.handelError));
  }

  getConstantById(id: string): Observable<Constant>//Observable<constant[]>
  {
    return this.http.get<Constant>(`${environment.apiUrl}/Constant/${id}`).pipe(catchError(this.handelError));
  }

  updateConstant(Evaluation: Constant): Observable<Constant> {
    return this.http.put<Constant>(`${environment.apiUrl}/Constant/${Evaluation.id}`, Evaluation, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });//.pipe(catchError(this.handelError));
  }

  addConstant(newRecord: Constant) {
    return this.http.post<Constant>(`${environment.apiUrl}/Constant`, newRecord, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(catchError(this.handelError));
  }

  deletConstant(Evaluation: Constant) {
    return this.http.delete<Constant>(`${environment.apiUrl}/Constant/${Evaluation.id}`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }).pipe(catchError(this.handelError));
  }

  getLanguageConstants(mId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetLanguageConstants/${mId}`);
  }

  // getCurriculums(): Observable<Curriculum[]> {
  //   return this.http.get<Curriculum[]>(`${environment.apiUrl}/Service/Curriculum`);
  // }

  // getGradesByUserId(curriculumId : Guid): Observable<Grade[]> {
  //   return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByUserId/${curriculumId}`);
  // }

  // getGradesByCurriculumId(curriculumId : Guid): Observable<Grade[]> {
  //   return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByCurriculumId/${curriculumId}`);
  // }

  getSubjectsByUserIdAndGradeId(gradeId: Guid): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsByUserIdAndGradeId/${gradeId}`);
  }

  getSubjectsByGradeId(gradeId: Guid): Observable<Subject[]> {
    return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsByGradeId/${gradeId}`);
  }

  // getLevels(): Observable<Level[]> {
  //   return this.http.get<Level[]>(`${environment.apiUrl}/Constant/GetSubscriptionGroup/15?langId=01-01`);
  // }

  getSubscriptionGroup(mId: string, LangId: string): Observable<Constant[]> {
    return this.http.get<Constant[]>(`${environment.apiUrl}/Constant/GetSubscriptionGroup/${mId}?langId=${LangId}`);
  }


  // getCategories(): Observable<Category[]> {
  //   return this.http.get<Category[]>(`${environment.apiUrl}/Constant/GetSubscriptionGroup/14?langId=01-01`);
  // }

  private handelError(errorResponse: HttpErrorResponse) {
    console.error(
      `Backend returned code ${errorResponse.error}, ` +
      `body was: ${errorResponse.error}`);
    return throwError('There is a problem with the service' + errorResponse.status);
  }

  getTeachersByDivisionSubjectId(subjectId: Guid, gradeDivisionId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetTeachersByDivisionSubjectId/${subjectId}/${gradeDivisionId}`);
  }

  getSubjectsByGradeDivision(Ids: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/GetSubjectsByGradeDivision`, Ids);
  }

  getSubjectsByStudent(Ids: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/GetSubjectsByStudent`, Ids);
  }

  getCurriculums(): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(`${environment.apiUrl}/Service/Curriculum`);
  }

  getGradesByUserId(curriculumId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByUserId/${curriculumId}`);
  }

  getGradesByCurriculumId(curriculumId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByCurriculumId/${curriculumId}`);
  }

  getGradeDivision(examId: Guid, isPublicExamAssign: boolean): Observable<ExamGradeDivisions[]> {
    return this.http.get<ExamGradeDivisions[]>(`${environment.apiUrl}/AssignExam/GetGradeDivision/${examId}/${isPublicExamAssign}`);
  }

  getServerDateTime(): Observable<Date> {
    return this.http.get<Date>(`${environment.apiUrl}/PrivateExam/GetServerDateTime`);
  }

  getSubscriptionLogo(ServiceId: Guid): Observable<Subscription> {
    return this.http.get<Subscription>(`${environment.apiUrl}/Profile/GetSubscriptionLogo/${localStorage.currentUserSubscription}`);
  }

  getServices(): Observable<Service[]> {
    return this.http.get<Service[]>(`${environment.apiUrl}/Service`);
  }

  getServicesBySubscriptionId(): Observable<Service[]> {
    return this.http.get<Service[]>(`${environment.apiUrl}/Service/BySubscription/${localStorage.currentUserSubscription}`);
  }

  getUserServices(): Observable<Service[]> {
    return this.http.get<Service[]>(`${environment.apiUrl}/Service/GetUserServices`);
  }

  getAllCurriculumsByServiceId(ServiceId: Guid): Observable<Curriculum[]> {
    return this.http.get<Curriculum[]>(`${environment.apiUrl}/Service/GetAllCurriculumsByServiceId/${ServiceId}`);
  }

  getMainCurriculums(): Observable<MainCurriculum[]> {
    return this.http.get<MainCurriculum[]>(`${environment.apiUrl}/MainCurriculum`);
  }

  getMainGradesByAcademicYear(academicYearId: Guid): Observable<AcademicYearGrade[]> {
    return this.http.get<AcademicYearGrade[]>(`${environment.apiUrl}/MainGrade/GetMainGradesByAcademicYear/${academicYearId}`);
  }

  getAllByCurriculumAndServiceId(curriculumId: Guid, serviceId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetAllByCurriculumAndServiceId/${curriculumId}/${serviceId}`);
  }

  getMainSubjects(MainCurriculumId: Guid): Observable<MainSubject[]> {
    return this.http.get<MainSubject[]>(`${environment.apiUrl}/MainSubject/GetAllByCurriculumId/${MainCurriculumId}`);
  }

  getSelectedSubjectsByGradeId(gradeId: Guid): Observable<GradeSubject[]> {
    return this.http.get<GradeSubject[]>(`${environment.apiUrl}/Subject/GetSelectedSubjectsByGradeId/${gradeId}`);
  }

  getDivisionsByGradeId(gradeId: Guid): Observable<GradeDivision[]> {
    return this.http.get<GradeDivision[]>(`${environment.apiUrl}/GradeDivision/GetDivisionsByGradeId/${gradeId}`);
  }

  getAddedSubjectsByGradeId(gradeId: Guid): Observable<GradeSubject[]> {
    return this.http.get<GradeSubject[]>(`${environment.apiUrl}/Subject/GetAddedSubjectsByGradeId/${gradeId}`);
  }

  getAllAcademicYears(serviceId: Guid, curriculumId: Guid): Observable<AcademicYear[]> {
    return this.http.get<AcademicYear[]>(`${environment.apiUrl}/AcadimicYear/GetAllAcademicYears/${serviceId}/${curriculumId}`);
  }

  addNewCurriculum(curriculum: Curriculum): Observable<Curriculum> {
    return this.http.post<Curriculum>(`${environment.apiUrl}/Service/AddNewCurriculum`, curriculum);
  }

  updateCurriculum(curriculum: Curriculum): Observable<Curriculum> {
    return this.http.put<Curriculum>(`${environment.apiUrl}/Service/Curriculum/${curriculum.id}`, curriculum);
  }

  addNewService(service: Service): Observable<Service> {
    return this.http.post<Service>(`${environment.apiUrl}/Service`, service);
  }

  updateService(service: Service): Observable<Service> {
    return this.http.put<Service>(`${environment.apiUrl}/Service/${service.id}`, service);
  }

  addNewAcademicYear(academicYear: AcademicYear): Observable<AcademicYear> {
    return this.http.post<AcademicYear>(`${environment.apiUrl}/AcadimicYear`, academicYear);
  }

  updateAcademicYear(academicYear: AcademicYear): Observable<AcademicYear> {
    return this.http.put<AcademicYear>(`${environment.apiUrl}/AcadimicYear/${academicYear.id}`, academicYear);
  }

  getStudentsByGradeId(gradeId: Guid, pageNo: number, pageSize: number): Observable<PagedGradeStudent> {
    return this.http.get<PagedGradeStudent>(`${environment.apiUrl}/Profile/GetStudentsByGradeId/${gradeId}?pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  createStudentProfile(gradeId: Guid, student: Student): Observable<Student> {
    return this.http.post<Student>(`${environment.apiUrl}/Profile/AddProfileWithGrade/${gradeId}`, student);
  }

  getStudentProfileById(studentId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/${studentId}`);
  }

  updateStudentProfile(student: Student): Observable<Student> {
    return this.http.put<Student>(`${environment.apiUrl}/Profile/${student.id}`, student);
  }

  deleteStudentProfile(studentId: Guid): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/Profile/${studentId}`);
  }

  changeStudentGrade(studentId: Guid, gradeId: Guid): Observable<Student> {
    return this.http.put<Student>(`${environment.apiUrl}/Profile/ChangeStudentGrade/${studentId}/${gradeId}`, {});
  }

  saveGradesToCurriculum(selectedGrades): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Grade/AddGradesToCurriculum`, selectedGrades);
  }

  addSubjectsToGrade(selectedGrades): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Subject/AddSubjectsToGrade`, selectedGrades);
  }

  getSemestersByAcademicYear(academicYearId: Guid): Observable<Semester[]> {
    return this.http.get<Semester[]>(`${environment.apiUrl}/Semester/GetAllByAcademicYear/${academicYearId}`);
  }

  addNewSemester(semester: Semester): Observable<Semester> {
    return this.http.post<Semester>(`${environment.apiUrl}/Semester`, semester);
  }

  updateSemester(semester: Semester, semesterId: Guid): Observable<Semester> {
    semester.id = semesterId;
    return this.http.put<Semester>(`${environment.apiUrl}/Semester/${semesterId}`, semester);
  }

  activateSemester(semesterId: Guid, academicYearId: Guid): Observable<Semester> {
    return this.http.put<Semester>(`${environment.apiUrl}/Semester/ActivateSemester/${semesterId}/${academicYearId}`, {});
  }

  getGradesByAcademicYearId(academicYearId: Guid): Observable<Grade[]> {
    return this.http.get<Grade[]>(`${environment.apiUrl}/Grade/GetGradesByAcademicYearId/${academicYearId}`);
  }

  getDivisionsByGradeIdAndSemesterId(gradeId: Guid, semesterId: Guid): Observable<GradeDivision[]> {
    return this.http.get<GradeDivision[]>(`${environment.apiUrl}/GradeDivision/GetDivisionsByGradeIdAndSemesterId/${gradeId}/${semesterId}`);
  }

  upateDivisionsInformation(divisions): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/GradeDivision/UpateDivisionsInformation`, divisions);
  }

  generateGradeDivisions(gradeId: Guid, semesterId: Guid, numberOfDivisions: number): Observable<GradeDivision[]> {
    return this.http.post<GradeDivision[]>(`${environment.apiUrl}/GradeDivision/GenerateGradeDivisions/${gradeId}/${semesterId}/${numberOfDivisions}`, {});
  }

  addNewDivision(division: GradeDivision): Observable<Semester> {
    return this.http.post<Semester>(`${environment.apiUrl}/GradeDivision`, division);
  }

  updateNumberOfDivisions(numberOfDivisions: number, gradeId: Guid): Observable<Semester> {
    return this.http.put<Semester>(`${environment.apiUrl}/Grade/UpdateNumberOfDivisions/${numberOfDivisions}/${gradeId}`, {});
  }

  getUnassignedStudentsByServiceId(serviceId: Guid, gradeId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/GetUnassignedStudentsByServiceId/${serviceId}/${gradeId}`);
  }

  getAssignedStudentsByDivisionId(divisionId: Guid): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.apiUrl}/Profile/GetAssignedStudentsByDivisionId/${divisionId}`);
  }

  addStudentToGradeDivision(studentId: Guid, divisionId: Guid): Observable<Student[]> {
    return this.http.post<Student[]>(`${environment.apiUrl}/Profile/AddStudentToGradeDivision/${studentId}/${divisionId}`, {});
  }

  removeStudentFromGradeDivision(studentId: Guid, divisionId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/RemoveStudentFromGradeDivision/${studentId}/${divisionId}`, {});
  }

  getAllStaffBySubscription(pageNo: number, pageSize: number): Observable<PagedStaff> {
    return this.http.get<PagedStaff>(`${environment.apiUrl}/Profile/GetAllStaffBySubscription/${localStorage.currentUserSubscription}?pageNo=${pageNo}&pageSize=${pageSize}`);
  }

  createStaffProfile(staff: Staff): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Profile/AddStaffProfile`, staff);
  }

  updateStaffProfile(Staff: Staff): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/Profile/UpdateStaffProfile/${Staff.id}`, Staff);
  }
  // getStaffProfileById(staffId: Guid): Observable<Staff[]> {
  //   return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/${staffId}`);
  // }

  getStaffProfileById(staffId: Guid): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/Profile/GetStaffProfileById/${staffId}`);
  }

  updateSatffProfile(staff: Staff): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/${staff.id}`, staff);
  }

  deleteStaffProfile(staffId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/${staffId}`, {});
  }

  deactivateStaffProfile(userId: Guid): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/DeactivateProfile/${userId}`, {});
  }

  activateStaffProfile(userId: Guid): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/ActivateProfile/${userId}`, {});
  }

  // getSubjectsByCurriculimIdAndGradeId(curriculumId: Guid, gradeId: Guid): Observable<Subject[]> {
  //   return this.http.get<Subject[]>(`${environment.apiUrl}/Subject/GetSubjectsByCurriculimIdAndGradeId/${curriculumId}/${gradeId}`);
  // }

  getDivisionsSubjectsByGradeId(gradeId: Guid, semesterId: Guid, serviceId: Guid): Observable<DivisionsSubjects[]> {
    return this.http.get<DivisionsSubjects[]>(`${environment.apiUrl}/GradeDivision/GetDivisionsSubjectsByGradeId/${gradeId}/${semesterId}/${serviceId}`);
  }

  getAllActiveStaffBySubscription(serviceId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetAllActiveStaffBySubscription/${localStorage.currentUserSubscription}/${serviceId}`);
  }

  createTeachingRelation(divisionId: Guid, subjectId: Guid, teacherUserId: Guid): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Profile/CreateTeachingRelation/${divisionId}/${subjectId}/${teacherUserId}`, {});
  }

  updateTeachingRelation(divisionId: Guid, subjectId: Guid, staffId: Guid): Observable<Staff> {
    return this.http.put<Staff>(`${environment.apiUrl}/Profile/UpdateTeachingRelation/${divisionId}/${subjectId}/${staffId}`, {});
  }

  getSupervisorsByAcademicYear(serviceId: Guid, academicYearId: Guid): Observable<Staff[]> {
    return this.http.get<Staff[]>(`${environment.apiUrl}/Profile/GetSupervisorsByAcademicYear/${serviceId}/${academicYearId}`);
  }

  getSupervisorRelations(serviceId: Guid, supervisorId: Guid): Observable<any[]> {
    return this.http.get<any[]>(`${environment.apiUrl}/Profile/GetSupervisorRelations/${serviceId}/${supervisorId}`);
  }

  deleteSupervisorRelation(relationId: Guid, supervisorId: Guid): Observable<any[]> {
    return this.http.delete<any[]>(`${environment.apiUrl}/Profile/DeleteSupervisorRelation/${relationId}/${supervisorId}`, {});
  }

  createSupervisorRelation(relations: any): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Profile/CreateSupervisorRelation`, relations);
  }

  changePassword(changePassword: any): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/Account/ChangePassword`, changePassword);
  }

  resetUserPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}/Account/ResetUserPassword/${email}`, {});
  }

  getExamsByServiceId(serviceId: Guid): Observable<Exam[]> {
    return this.http.get<Exam[]>(`${environment.apiUrl}/PrivateExam/GetExamsByServiceId/${serviceId}`);
  }

  getUsersRoles(): Observable<UsersRoles[]> {
    return this.http.get<UsersRoles[]>(`${environment.apiUrl}/Profile/GetUsersRoles`);
  }

  // getExamsByFilters(serviceId: Guid, gradeId: Guid, roundId: string, skillId: string): Observable<Exam[]> {
  //   return this.http.get<Exam[]>(`${environment.apiUrl}/PrivateExam/GetExamsByFilters/${serviceId}/${gradeId}/${roundId}/${skillId}`);
  // }

  getExamsByFilters(filter: ReportQuestionsAnswersCountFilter): Observable<any[]> {
    let httpParams = new HttpParams();
    Object.keys(filter).forEach(function (key) {
      httpParams = httpParams.append(key, filter[key]);
    });
    return this.http.get<any[]>(`${environment.apiUrl}/PrivateExam/GetExamsByFilters?${httpParams.toString()}`);
  }

  getReportsSetup(): Observable<Report[]> {
    return this.http.get<Report[]>(`${environment.apiUrl}/Report`);
  }

  saveReportSetup(report: Report): Observable<Report> {
    return this.http.post<Report>(`${environment.apiUrl}/Report`, report);
  }

  updateReportSetup(report: Report): Observable<Report> {
    return this.http.put<Report>(`${environment.apiUrl}/Report/${report.id}`, report);
  }

  getReportSetupByIndexTreeNodeId(reportId: Guid): Observable<Report> {
    return this.http.get<Report>(`${environment.apiUrl}/Report/GetReportSetupByIndexTreeNodeId/${reportId}`);
  }

  getReportFiltersByReportId(reportId: Guid): Observable<ReportFilter[]> {
    return this.http.get<ReportFilter[]>(`${environment.apiUrl}/Report/GetReportFiltersByReportId/${reportId}`);
  }

  saveReportFilter(reportFilter: ReportFilter): Observable<ReportFilter> {
    return this.http.post<ReportFilter>(`${environment.apiUrl}/Report/AddReportFilter`, reportFilter);
  }

  updateReportFilter(reportFilter: ReportFilter): Observable<ReportFilter> {
    return this.http.put<ReportFilter>(`${environment.apiUrl}/Report/UpdateReportFilter/${reportFilter.id}`, reportFilter);
  }

  deleteReportFilter(reportId: Guid): Observable<ReportFilter> {
    return this.http.delete<ReportFilter>(`${environment.apiUrl}/Report/DeleteReportFilter/${reportId}`, {});
  }
}
