import { Injectable } from '@angular/core';
import { Settings } from './app.settings.model';

@Injectable({ providedIn: 'root' })
export class AppSettings {
    public settings = new Settings(
        localStorage.getItem('name') || 'Edulinkin',                         //theme name
        true,                                                               //loadingSpinner
        localStorage.getItem('fixedHeader') === 'false' ? false : true,     //fixedHeader
        localStorage.getItem('fixedSidenav') === 'false' ? false : true,    //fixedSidenav
        localStorage.getItem('fixedSidenavUserContent') === 'true' ? true : false,  //fixedSidenavUserContent
        localStorage.getItem('fixedFooter') === 'true' ? true : false,      //fixedFooter
        localStorage.getItem('sidenavIsOpened') === 'false' ? false : true, //sidenavIsOpened
        localStorage.getItem('sidenavIsPinned') === 'false' ? false : true, //sidenavIsPinned  
        localStorage.getItem('menu') || 'vertical',                         //horizontal , vertical
        localStorage.getItem('menuType') || 'default',                      //default, compact, mini
        //localStorage.getItem('theme') || 'indigo-light' + ' ' + 'ltm.edulinkin.com',
        localStorage.getItem('theme') || 'indigo-light' + ' ' + location.hostname ,                    //indigo-light, teal-light, red-light, gray-light, blue-dark, green-dark, pink-dark, gray-dark
        localStorage.getItem('rtl') === 'true' ? true : localStorage.getItem('rtl') === 'false' ? false : true,              // true = rtl, false = ltr
    )
}

