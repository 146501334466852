import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpInterceptor } from '@angular/common/http';
import { Observable, observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../auth/authentication.service';
import { ErrorDialogComponent } from '../../shared-component/ui-shared/error-dialog/error-dialog.component';
import { MatDialog } from '@angular/material/dialog'
@Injectable()

export class GlobalHttpErrorInterceptorService implements HttpInterceptor {
    private dialogConfig = {
        //width: '400px',
        disableClose: true,
        data: {}
    }
    private isDialogOpen: boolean = false;
    constructor(public _router: Router,
        private authenticationService: AuthenticationService,
        private dialog: MatDialog,
        private translate: TranslateService) { };
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.error instanceof ErrorEvent) {
                        console.error('Client side Error:', error.error);
                    }
                    else {
                        switch (error.status) {
                            // case 0: //bad Request
                            //     //console.error('error is intercept: ' + error.error.title);
                            //     //console.log('error status: ' + error.status);
                            //     if (!this.isDialogOpen) {
                            //         this.dialogConfig.data = { 'errorMessage': 'Unable to Connect to the Server status code: ' + error.status };
                            //         let dialogRef = this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                            //         this.isDialogOpen = true;
                            //         dialogRef.afterClosed().subscribe(() => { this.isDialogOpen = false; });
                            //     }
                            //     break;
                            case 102: //bad Request
                                this.dialogConfig.data = {
                                    'errorMessage': error.error.title ? error.error.title : this.translate.instant(error.error)
                                    //+ '  status code: ' + error.status 
                                };
                                this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                                break;
                            case 400: //bad Request
                                this.dialogConfig.data = {
                                    'errorMessage': error.error.title ? error.error.title : this.translate.instant(error.error)
                                    //+ ' status code: ' + error.status 
                                };
                                this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                                break;
                            case 401: //bad Request
                                // auto logout if 401 response returned from api
                                this.authenticationService.logout();
                                location.reload();
                                break;
                            case 403: //
                                this.dialogConfig.data = { 'errorMessage': this.translate.instant('Your access has been forbidden...') };
                                this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                                this._router.navigateByUrl("/");
                                break;
                            case 404: //Not Found                               
                                this.dialogConfig.data = { 'errorMessage': this.translate.instant('No data to display...') };
                                this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                                break;
                            case 408: //Request Timeout
                                this.dialogConfig.data = {
                                    'errorMessage': error.error.title ? error.error.title : this.translate.instant(error.error)
                                    //+ ' status code: ' + error.status 
                                };
                                this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                                break;
                            case 503: // sql problem                
                                this.dialogConfig.data = {
                                    'errorMessage': error.error.title ? error.error.title : this.translate.instant(error.error)
                                    //+ ' status code: ' + error.status 
                                };
                                this.dialog.open(ErrorDialogComponent, this.dialogConfig);
                                break;
                        }

                    }
                }
                //console.log('error status: ' + error.status);
                //console.log(error);
                return throwError(error);
            })
        )
    }
}