import { AppSettings } from 'src/app/app.settings';
import { Settings } from './../app.settings.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { SessionUser } from './session.user';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<SessionUser>;
    public currentUser: Observable<SessionUser>;
    public settings: Settings;

    constructor(private http: HttpClient,
        public appSettings: AppSettings,
        private router: Router) {
        this.settings = this.appSettings.settings;
        // If you are confident that the localStorage.getItem() 
        // call can never return null you can use the non-null assertion operator to tell typescript that you know what you are doing:
        //this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser') || '{}'));
        this.currentUserSubject = new BehaviorSubject<SessionUser>(JSON.parse(localStorage.getItem('sessionUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): SessionUser {
        if (this.currentUserSubject.value != null)
            return this.currentUserSubject.value;
        else
            return new SessionUser;
    }

    login(username: string, password: string) {
        return this.http.post<SessionUser>(`${environment.apiUrl}/Account/Token`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('sessionUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));
    }

    logout() {
        this.settings.sidenavIsOpened = true;
        // remove user from local storage to log user out
        var returnUrl = localStorage.returnUrl;
        localStorage.clear();

        this.currentUserSubject.next(null);

        if (returnUrl) {
            window.location.href = returnUrl;
        } else {
            this.router.navigate(['/login']);
        }
    }

    getCurrentUserRole(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/Account/me`);
    }

    getMyServices(): Observable<any[]> {
        return this.http.get<any[]>(`${environment.apiUrl}/Service/GetUserServices`);
    }


}